import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class ErrorsService {
  mapServerErrorsToForm(errors: { [key: string]: string[] }, form: FormGroup) {
    for (const errorField of Object.keys(errors)) {
      const formControl = form.get(errorField);
      if (formControl) {
        let index = 0;
        const fieldErrors: { [key: string]: string } = {};
        for (const error of errors[errorField]) {
          fieldErrors[`serverError${index++}`] = error;
        }
        formControl.setErrors(fieldErrors);
      }
    }
  }
}
